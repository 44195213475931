'use strict'

{ fixLatOrLng } = require '../../../src/frontend/helper/search/helper/m_fix_lat_lng'

require 'optimize-suite'

{Optimize} = window
{config} = Optimize.suiteData
config.finch = config: Optimize.finchConfig

# trim lat/lng digits
{ lat, lng } = config.user
Object.assign config.user, { lat: fixLatOrLng(lat), lng: fixLatOrLng(lng) }

Optimize.config = config
OptimizeSuite.init config
